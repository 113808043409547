import { ComponentStylesWithFns } from '@/lib/styles/styles.types';
import { EmploymentStatusType } from '@cstactics/types';

const styles: ComponentStylesWithFns<{ status?: EmploymentStatusType }> = {
  root: ({ status }) => [
    {
      'width': '100%',
      'height': 'fit-content',
      'boxSizing': 'border-box',

      'display': 'flex',
      'flexDirection': 'column',
      'cursor': 'pointer',

      'borderRadius': [1],
      'overflow': 'hidden',

      'transition': 'ease .5s',
      'border': '1px solid transparent',
      '&:hover': {
        zIndex: 999,
        filter: 'drop-shadow(0px 10px 18px rgba(34, 211, 238, 0.36)) drop-shadow(0px 4px 6px rgba(34, 211, 238, 0.48))',
        borderColor: `cyan.main`,
        //transform: 'scale(1.01)',
      },
    },
    status === EmploymentStatusType.HIRED && {
      borderColor: `cyan.main`,
    },
  ],
  topContainer: {
    width: '100%',
    height: 'fit-content',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'flex-end',
    position: 'relative',
  },
  cardImageContainer: {
    width: '100%',
    height: 'fit-content',
    transition: 'ease 1s',
  },
  image: {
    maskImage: 'linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 1) 50%)',
  },
  statusTag: {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: 4,
  },
  cardTextContainer: {
    position: 'absolute',
    width: '100%',
    padding: 4,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    gap: 2,
  },
  tagAndRole: {
    display: 'flex',
    gap: 2,
  },
  roleIcon: {
    color: 'contentSecondary.primary',
    backgroundColor: 'bgSecondary.primary',
    paddingY: 1,
    paddingX: 2,
    borderRadius: '18px',
    fontSize: '20px',
    width: 'fit-content',
    //
    maxWidth: [132, 100, 100, 100],
  },
  roleIconLabel: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  bottomContainer: {
    backgroundColor: 'bgPrimary.secondary',
    padding: 4,
  },
  bottomInfo: {
    width: '100%',
    boxSizing: 'border-box',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  bottomContainerElement: {
    display: 'flex',
    height: '60px',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  bottomLabel: {
    color: 'contentPrimary.tertiary',
  },
  action: {
    marginTop: 4,
    display: 'block',
    width: '100%',
  },
};

export { styles as AppAthleteCardStyles };

import { ComponentStylesWithFns } from '@/lib/styles/styles.types';
import { EmploymentStatusType } from '@cstactics/types';
import { palette } from '@/theme/palette';

const getColor = (status?: EmploymentStatusType) => {
  switch (status) {
    case EmploymentStatusType.HIRED:
      return palette.success;
    case EmploymentStatusType.BLOCKED:
      return palette.error;
    case EmploymentStatusType.NOT_HIRED:
      return palette.warning;
    default:
      return palette.success;
  }
};

const styles: ComponentStylesWithFns<{ status?: EmploymentStatusType }> = {
  root: {},
  glow: ({ status }) => [
    {
      paddingY: 1,
      paddingLeft: '12px',
      paddingRight: 2,
      backdropFilter: 'blur(16px)',
      borderRadius: '16px',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      gap: '12px',
      backgroundColor: getColor(status).dark,
    },
  ],
  dot: ({ status }) => [
    {
      height: 8,
      width: 8,
      borderRadius: '50%',
      backgroundColor: getColor(status).main,
    },
  ],
  text: ({ status }) => [
    {
      lineHeight: '',
      color: getColor(status).main,
    },
  ],
};

export { styles as StatusTagStyles };

import { ComponentStylesWithFns, StyleStates } from '@/lib/styles/styles.types';
import { palette } from '@/theme/palette';

interface CurrencyStylesProps extends StyleStates {
  color?: string;
  size: 'tiny' | 'small' | 'medium' | 'large';
}

const iconDimensions = {
  tiny: 12,
  small: 16,
  medium: 18,
  large: 32,
};

const styles: ComponentStylesWithFns<CurrencyStylesProps> = {
  root: ({ size }) => [
    {
      display: 'flex',
      alignItems: 'center',
      gap: 2,
    },
    size === 'medium' && {
      gap: 1,
    },
    size === 'small' && {
      gap: 1,
    },
  ],
  amount: ({ size, color }) => [
    {
      color: `${color}.main`,
      fontSize: iconDimensions[size],
    },
    size === 'medium' && {
      fontWeight: 600,
    },
    size === 'large' && {
      fontWeight: 600,
    },
  ],
  iconContainer: ({ size }) => [
    {
      height: iconDimensions[size] + 4,
      width: iconDimensions[size] + 4,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      svg: {
        height: iconDimensions[size] + 4,
        width: iconDimensions[size] + 4,
      },
    },
  ],
  icon: ({ color }) => [
    // { height: iconDimensions[size], width: iconDimensions[size] },
    !!color && { path: { stroke: palette[color].main } },
  ],
};

export { styles as CurrencyStyles };

import { AppRoute } from '@/_routes';
import MixpanelAPI from '@/lib/mixpanel/mixpanel.api';
import { getRequest } from '@/lib/utils/request';
import { signOut } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

export const useLogout = () => {
  const { push: navigate } = useRouter();
  const logout = useCallback(async () => {
    await getRequest(`/api/auth/logout`);
    await MixpanelAPI.logout();
    await signOut({ redirect: true, callbackUrl: AppRoute.LANDING });
  }, [navigate]);

  return logout;
};

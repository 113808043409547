import { ComponentStylesWithFns } from '@/lib/styles/styles.types';

const styles: ComponentStylesWithFns<{ disableNavigation: boolean }> = {
  root: ({ disableNavigation }) => [
    {
      position: 'sticky',
      top: 0,
      display: 'flex',
      flexDirection: 'column',
      zIndex: 99999999999,
      backgroundColor: 'bgPrimary.secondary',
      borderBottom: '1px solid',
      borderColor: 'bgPrimary.tertiary',
    },
    disableNavigation && {
      pointerEvents: 'none',
    },
  ],
  navbar: {
    paddingX: 3,
    paddingY: [1, 2, 3, 3],
    height: 'fit-content',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  // LEFT
  leftContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    marginRight: 4,
    a: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    img: {
      height: 24,
    },
  },
  links: {
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    marginLeft: 3,
  },
  discord: {
    '.MuiBox-root': {
      'color': 'white',
      '&:hover': {
        color: 'white',
      },
    },
  },
  // RIGHT
  rightContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 3,
  },
  loginButton: {
    marginRight: [1, 2, 3, 3],
  },
  registerButton: {
    marginRight: [1, 2, 0, 0],
  },
  signOutButton: {
    marginLeft: [1, 2, 3, 3],
  },
  menuCloseWrapper: {
    position: 'fixed',
    inset: 0,
    zIndex: 1,
  },
  dropDownMenu: {
    position: 'absolute',
    top: 84,
    right: 0,
    marginTop: 4,
    marginRight: 3,
    width: 256,
    maxHeight: 'calc(100vh - 132px)',
    height: 'fit-content',
    overflowY: 'auto',
    boxSizing: 'border-box',
  },
  notificationContainer: {
    position: 'absolute',
    width: ['100%', '100%', 'calc(100% - 84px)', 'calc(100% - 270px)'],
    display: 'flex',
    justifyContent: 'center',
    marginLeft: [0, 0, '84px', '270px'],
  },
};

export { styles as TopNavigationStyles };

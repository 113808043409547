import { useGameAssetsStore } from '@/lib/store/gameAssets/game-assets.store';
import { useStyles } from '@/lib/styles/styles.hooks';
import { SxProps, Theme, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Employable, EmploymentStatusType } from '@cstactics/types';
import { FC, useMemo } from 'react';
import { StatusTagStyles } from './StatusTag.styles';

interface StatusTagProps {
  /** athlete */
  employable: Employable;

  /** status */
  status: EmploymentStatusType;

  /** styles */
  sx?: SxProps<Theme>;
}

const StatusTag: FC<StatusTagProps> = ({ employable, status, sx }) => {
  const styles = useStyles(StatusTagStyles, { status }, sx);
  const { getEmployableBlockedDistance } = useGameAssetsStore((state) => state);

  const label = useMemo(() => {
    if (status === EmploymentStatusType.BLOCKED) {
      return `Available in ${getEmployableBlockedDistance(employable)}`;
    } else if (status === EmploymentStatusType.NOT_HIRED) {
      return 'Available';
    } else {
      return 'Hired';
    }
  }, [status]);

  return (
    <Box className="StatusTag-root" sx={styles.root}>
      <Box className="StatusTag-glow" sx={styles.glow}>
        <Box className="StatusTag-dot" sx={styles.dot} />
        <Typography variant="caption" className="TeamStatusTag-text" sx={styles.text}>
          {label}
        </Typography>
      </Box>
    </Box>
  );
};

export default StatusTag;

import { SxProps, Theme, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { FC, useMemo } from 'react';
import { useStyles } from '@/lib/styles/styles.hooks';
import { CurrencyStyles } from './Currency.styles';
import { CurrencyType } from '@cstactics/types';
import { PremiumCurrencyIcon, SoftCurrencyIcon } from '@/components/atoms/Icons/MenuIcons/MenuIcons';

interface CurrencyProps {
  type: CurrencyType;
  amount: number;
  balance?: number;
  color?: string;
  size?: 'small' | 'medium' | 'large';
  isPredicted?: boolean;
  /** styles */
  sx?: SxProps<Theme>;
}

const CurrencyFormats = [
  { factor: 1, indicator: '' },
  { factor: 1000, indicator: 'k' },
  { factor: 1000000, indicator: 'm' },
  { factor: 1000000000, indicator: 'b' },
];

const Currency: FC<CurrencyProps> = ({ sx, type, amount, balance, color, size = 'medium', isPredicted }) => {
  const internalColor = useMemo(() => (balance && balance < amount ? 'error' : color), [color, balance, amount]);

  const styles = useStyles(CurrencyStyles, { size, color: internalColor }, sx);

  const formatedAmount = useMemo(() => {
    const formatNumber = (num: number, factor: number, indicator: string): string => {
      const x = Math.round((num / factor) * 100) / 100;
      return `${x}${indicator}`;
    };

    const isNegative = amount < 0;
    const absoluteAmount = Math.abs(amount);

    const { factor, indicator } = CurrencyFormats.find((x, index) => {
      if (!CurrencyFormats[index + 1]) return true;
      return absoluteAmount < CurrencyFormats[index + 1].factor;
    })!;

    return `${isPredicted ? '~' : ''}${isNegative ? '-' : ''}${formatNumber(absoluteAmount, factor, indicator)}`;
  }, [amount, isPredicted]);

  return (
    <Box className="Currency-root" sx={styles.root}>
      <Box sx={styles.iconContainer}>
        {type === CurrencyType.SOFT_CURRENCY ? (
          <SoftCurrencyIcon sx={styles.icon} />
        ) : (
          <PremiumCurrencyIcon sx={styles.icon} />
        )}
      </Box>
      <Typography variant="body" sx={styles.amount}>
        {formatedAmount}
      </Typography>
    </Box>
  );
};

export default Currency;

import { StateCreator } from 'zustand';
import { UiState } from '../ui.store';
import {
  MatchCommentary,
  MatchResultAthleteCard,
  PreMatchAnalysis,
  RoundAnalysis,
  TeamBaseInfo,
  TeamStats,
  UserMatchResult,
} from '@cstactics/types';
import { initMatchSimulation, setPlayerSpeed, togglePlayPause } from './matchSimulation.actions';

export enum PlayerState {
  PLAY = 'play',
  PAUSE = 'pause',
  FINISHED = 'finished',
}

export enum PlayerSpeed {
  NORMAL = 'Normal',
  FAST = 'Fast',
  SUPER_FAST = 'Super Fast',
}

export interface MatchSimulationSlice {
  initMatchSimulation: (matchResult: UserMatchResult, matchCommentary: MatchCommentary) => void;

  // PLAYER
  playerState: PlayerState;
  playerSpeed: PlayerSpeed;

  togglePlayPause: (matchCommentary: MatchCommentary) => void;
  skipFwd: () => void;
  setPlayerSpeed: (playerSpeed: PlayerSpeed) => void;

  // TIMERS
  matchTotalTime: number;
  matchCurrentTime: number;
  roundTotalTime: number;
  roundCurrentTime: number;

  // INTERVAL
  interval?: ReturnType<typeof setInterval>;

  // MATCH
  matchId?: string;
  activeRoundNumber: number;
  activeRound?: RoundAnalysis | PreMatchAnalysis;
  rounds?: [PreMatchAnalysis, ...RoundAnalysis[]];
  team?: {
    info: TeamBaseInfo;
    stats: TeamStats;
    roster: MatchResultAthleteCard[];
  };
  opponent?: {
    info: TeamBaseInfo;
    stats: TeamStats;
    roster: MatchResultAthleteCard[];
  };

  setActiveRound: (activeRound: number) => void;

  // athleteInAction: {
  //   athleteId: number;
  //   type: 'CT' | 'T';
  // } | null;
  // matchSimulationResult: {
  //   win: number;
  //   loss: number;
  //   simulationDone: boolean;
  // };

  //   setAthleteInAction: (
  //     athleteInAction: {
  //       athleteId: number;
  //       type: 'CT' | 'T';
  //     } | null
  //   ) => void;
  //   setMatchSimulationResult: ({
  //     win,
  //     loss,
  //     simulationDone,
  //     reset,
  //   }: {
  //     win?: number;
  //     loss?: number;
  //     simulationDone?: boolean;
  //     reset?: boolean;
  //   }) => void;
}

export const createMatchSimulationSlice: StateCreator<UiState, [], [], MatchSimulationSlice> = (
  setState,
  getState
) => ({
  initMatchSimulation(matchResult: UserMatchResult, matchCommentary: MatchCommentary) {
    initMatchSimulation(matchResult, matchCommentary, { setState, getState });
  },

  // PLAYER

  playerState: PlayerState.PAUSE,
  playerSpeed: PlayerSpeed.NORMAL,

  togglePlayPause(matchCommentary: MatchCommentary) {
    togglePlayPause({ setState, getState }, matchCommentary);
  },
  skipFwd() {
    setState(({ activeRoundNumber }) => ({
      activeRoundNumber: activeRoundNumber + 1,
    }));
  },
  setPlayerSpeed(playerSpeed: PlayerSpeed) {
    setPlayerSpeed(playerSpeed, { setState, getState });
  },

  // TIMERS
  matchTotalTime: 0,
  matchCurrentTime: 0,
  roundTotalTime: 0,
  roundCurrentTime: 0,

  // INTERVAL
  interval: undefined,

  // MATCH
  matchId: undefined,
  activeRoundNumber: 0,
  activeRound: undefined,
  rounds: undefined,
  roundsWon: 0,
  roundsLost: 0,
  team: undefined,
  opponent: undefined,

  // vetoHistoryDone: false,
  // athleteInAction: null,
  // matchSimulationResult: {
  //   win: 0,
  //   loss: 0,
  //   simulationDone: false,
  // },

  /** actions */

  setActiveRound: (activeRoundNumber: number) => {
    setState({ activeRoundNumber });
  },

  // setAthleteInAction: (athleteInAction: { athleteId: number; type: 'CT' | 'T' } | null) => {
  //   setState({ athleteInAction });
  // },
  // setMatchSimulationResult: ({
  //   win,
  //   loss,
  //   simulationDone,
  //   reset,
  // }: {
  //   win?: number;
  //   loss?: number;
  //   simulationDone?: boolean;
  //   reset?: boolean;
  // }) => {
  //   setState((state) => {
  //     if (reset) {
  //       return {
  //         matchSimulationResult: {
  //           win: 0,
  //           loss: 0,
  //           simulationDone: false,
  //         },
  //       };
  //     }

  //     return {
  //       matchSimulationResult: {
  //         win: win ? state.matchSimulationResult.win + win : state.matchSimulationResult.win,
  //         loss: loss ? state.matchSimulationResult.loss + loss : state.matchSimulationResult.loss,
  //         simulationDone: simulationDone ?? state.matchSimulationResult.simulationDone,
  //       },
  //     };
  //   });
  // },
});

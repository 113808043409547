export type ImageType = 'athlete' | 'staff' | 'rooms' | 'sponsorship-contracts' | 'currency' | 'profile';

export function getImageUrl(key: string, type: ImageType): string {
  if (!key) {
    // console.warn('No key provided for image');
    return '';
  }

  const junks = key.split('.');

  // add png extension if no extension is provided
  if (junks.length === 1) {
    key += '.png';
  }

  let prefix: string = type;

  if (type === 'athlete' || type === 'staff') {
    prefix = `renders/${type}`;
  }

  return process.env.NEXT_PUBLIC_S3_MEDIA_BUCKET_URL + prefix + '/' + key;
}

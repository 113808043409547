import { MatchCommentary, PreMatchAnalysis, RoundAnalysis, UserMatchResult } from '@cstactics/types';
import { UiState } from '../ui.store';
import { PlayerSpeed, PlayerState } from './matchSimulation.slice';

const ROUND_CHANGE_TIME = 5;

interface StoreActions {
  setState: (
    partial: UiState | Partial<UiState> | ((state: UiState) => UiState | Partial<UiState>),
    replace?: boolean | undefined
  ) => void;
  getState: () => UiState;
}

export const PlayerSpeedIntervalTime: Record<PlayerSpeed, number> = {
  'Normal': 1000,
  'Fast': 200,
  'Super Fast': 50,
};

/**
 * Initialize match simulation page
 * @param matchResult
 * @param matchCommentary
 * @param store
 */
export function initMatchSimulation(
  matchResult: UserMatchResult,
  matchCommentary: MatchCommentary,
  store: StoreActions
): void {
  const { matchId } = store.getState();

  // do not re-init if matchId is the same
  if (matchResult.matchId === matchId) return;

  const hasPreMatch = matchCommentary.preMatch?.duration > 0;

  const roundTimers = [
    hasPreMatch ? matchCommentary.preMatch.duration + ROUND_CHANGE_TIME : 0,
    ...Object.values(matchCommentary.matchRounds).map((round) => round.duration + ROUND_CHANGE_TIME),
  ];

  const matchTotalTime = roundTimers.reduce((acc, time) => acc + time, 0);

  const activeRoundNumber = hasPreMatch ? 0 : 1;
  const activeRound = hasPreMatch ? matchCommentary.preMatch : Object.values(matchCommentary.matchRounds)[0];

  store.setState({
    activeRoundNumber,
    activeRound,
    rounds: [matchCommentary.preMatch, ...Object.values(matchCommentary.matchRounds)],
    matchTotalTime: matchTotalTime,
    matchCurrentTime: 0,
    roundTotalTime: activeRound.duration,
    roundCurrentTime: 0,
    team: matchResult.team,
    opponent: matchResult.opponent,
  });
}

/**
 * Toggle play/pause
 * @param store
 */
export function togglePlayPause(store: StoreActions, matchCommentary: MatchCommentary): void {
  const { playerState, playerSpeed, interval } = store.getState();

  console.debug('togglePlayPause', { playerState, playerSpeed, interval });

  // handle play
  if (playerState === PlayerState.PAUSE) {
    const currentInterval = setInterval(() => {
      handleMatchTick(store);
    }, PlayerSpeedIntervalTime[playerSpeed]);

    store.setState(({ interval }) => {
      if (interval) clearInterval(interval);

      return {
        playerState: PlayerState.PLAY,
        interval: currentInterval,
      };
    });
  }

  // handle pause
  else if (playerState === PlayerState.PLAY) {
    store.setState(({ interval }) => {
      if (interval) clearInterval(interval);

      return {
        playerState: PlayerState.PAUSE,
        interval: undefined,
      };
    });
  }

  // handle restart
  else if (playerState === PlayerState.FINISHED) {
    const hasPreMatch = matchCommentary.preMatch?.duration > 0;

    store.setState({
      activeRoundNumber: hasPreMatch ? 0 : 1,
      activeRound: hasPreMatch ? matchCommentary.preMatch : Object.values(matchCommentary.matchRounds)[0],
      matchCurrentTime: 0,
      roundCurrentTime: 0,
    });

    const currentInterval = setInterval(() => {
      handleMatchTick(store);
    }, PlayerSpeedIntervalTime[playerSpeed]);

    store.setState(({ interval }) => {
      if (interval) clearInterval(interval);

      return {
        playerState: PlayerState.PLAY,
        interval: currentInterval,
      };
    });
  }
}

/**
 * Set player speed
 * @param playerSpeed
 */
export function setPlayerSpeed(playerSpeed: PlayerSpeed, store: StoreActions): void {
  const { playerState } = store.getState();

  store.setState({ playerSpeed });

  if (playerState === PlayerState.PLAY) {
    const interval = store.getState().interval;
    clearInterval(interval);

    const newInterval = setInterval(() => {
      handleMatchTick(store);
    }, PlayerSpeedIntervalTime[playerSpeed]);

    store.setState({
      interval: newInterval,
    });
  }
}

/**
 * Handle match tick
 * @param store
 */
export function handleMatchTick(store: StoreActions): void {
  store.setState(
    ({ matchCurrentTime, matchTotalTime, roundCurrentTime, interval, activeRound, rounds, activeRoundNumber }) => {
      if (activeRound === undefined || !rounds) throw new Error('activeRound or rounds is undefined');

      // handle end of round
      if (roundCurrentTime >= activeRound.duration + ROUND_CHANGE_TIME) {
        const { states, isEndOfMatch } = handleEndOfRound(matchCurrentTime, matchTotalTime, rounds, activeRoundNumber);

        if (isEndOfMatch) {
          clearInterval(interval);
          states.playerState = PlayerState.FINISHED;
          states.interval = undefined;
        }

        return states;
      }

      // normal tick
      return {
        matchCurrentTime: matchCurrentTime + 1,
        roundCurrentTime: roundCurrentTime + 1,
      };
    }
  );
}

/**
 * Handle End of Round
 */
export function handleEndOfRound(
  matchCurrentTime: number,
  matchTotalTime: number,
  rounds: [PreMatchAnalysis, ...RoundAnalysis[]],
  activeRoundNumber: number
): { states: Partial<UiState>; isEndOfMatch: boolean } {
  if (!rounds) throw new Error('activeRound or rounds is undefined');

  if (!rounds[activeRoundNumber + 1]) {
    return {
      states: {
        activeRoundNumber: undefined,
        activeRound: undefined,
        roundCurrentTime: undefined,
        roundTotalTime: undefined,
        matchCurrentTime: matchCurrentTime + 1,
        matchTotalTime,
      },
      isEndOfMatch: true,
    };
  }

  return {
    states: {
      activeRoundNumber: activeRoundNumber + 1,
      activeRound: rounds[activeRoundNumber + 1],
      matchCurrentTime: matchCurrentTime + 1,
      roundCurrentTime: 1,
      roundTotalTime: rounds[activeRoundNumber + 1].duration,
    },
    isEndOfMatch: false,
  };
}
